<template>
  <div
    v-shortkey="['shift', 'esc']"
    class="footer--outer"
    @shortkey="loginOrToggleUI"
  >
    <!-- TODO (ESS) : make this location slug dynamic somehow (location summary from site) -->
    <WithLocation v-slot="{ isLocationLoading, theLocation }" slug="treps">
      <BasePageSection
        :class="['footer', 'py-0']"
        bg-color="#707070"
        title=""
        :pa-0="['xs', 'sm'].includes($mq)"
        full-bleed-bg
      >
        <VLayout>
          <VContainer class="locations pa-0" fluid grid-list-md>
            <VLayout justify-center align-space-around row wrap fill-height>
              <VFlex sm4 xs12>
                <BaseThemeComponent
                  v-if="!isLocationLoading"
                  name="FooterLocation"
                  :location="theLocation"
                />
              </VFlex>
              <VFlex
                :class="['right-col text-md-right']"
                grow
                sm8
                text-sm-right
                text-xs-center
                xs12
              >
                <BaseThemeComponent
                  v-if="!isLocationLoading"
                  name="FooterMenu"
                  :location="theLocation"
                />
              </VFlex>
            </VLayout>
            <VLayout row wrap>
              <VFlex xs12 sm8 md8 text-xs-center text-sm-left>
                <BaseButtonSendMessage
                  component="BaseButtonOutline"
                  class="ml-0"
                />
              </VFlex>
            </VLayout>
            <VLayout row wrap>
              <VFlex xs12 pa-3 text-xs-center>
                <img
                  style="max-width:300px;width:100%;height:auto"
                  src="https://scontent-lax3-1.cdninstagram.com/v/t51.29350-15/274959122_383667649763602_6503238308330707938_n.jpg?_nc_cat=110&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=lbU8E01d7F8AX_S6Dg8&_nc_ht=scontent-lax3-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT98uueH2lheFyiP-PyEtIa_e8j791Pf-zmMu2YzQDlOGw&oe=62286993"
                />
              </VFlex>
            </VLayout>
          </VContainer>
        </VLayout>
        <BaseRestoByline link-color="#fff" />
        <VFlex
          text-xs-center
          xs12
          white--text
          mt-4
          :pt-2="['xs', 'sm'].includes($mq)"
        >
          <span>&copy; {{ new Date().getFullYear() }} {{ getLegalName }}</span>
        </VFlex>
      </BasePageSection>
    </WithLocation>
    <BaseModalsAndSnackbars
      email-header-class="treps-blue white--text"
      email-color="treps-navy"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TrepsFooter',
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getLegalName']),
  },
  methods: {
    ...mapActions('auth', ['loginOrToggleUI']),
  }, // methods
}
</script>

<style lang="scss">
.footer .section-content > .container {
  width: 100%;
  max-width: 1440px;
}
.footer-contact-btn {
  max-width: 100%;
}
</style>
